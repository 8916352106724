<template>
  <div>
    <div class="box"></div>
    <div class="tabbar">
      <div @click="goPath('index')">
        <img class="icon-tab" :src="require('../../assets/tabbar/cf' + (value == 'index' ? '1.png' : '0.png'))">
        <div :class="value == 'index' ? 'icon-txt2' : 'icon-txt'">{{$t('cf')}}</div>
      </div>
      <div @click="goPath('introduce')">
        <img class="icon-tab" :src="require('../../assets/tabbar/js' + (value == 'introduce' ? '1.png' : '0.png'))">
        <div :class="value == 'introduce' ? 'icon-txt2' : 'icon-txt'">{{$t('jst')}}</div>
      </div>
      <img class="icon-huojian" @click="goPath('firing')" src="../../assets/tabbar/hj.png">
      <div @click="goPath('team')">
        <img class="icon-tab" :src="require('../../assets/tabbar/td' + (value == 'team' ? '1.png' : '0.png'))">
        <div :class="value == 'team' ? 'icon-txt2' : 'icon-txt'">{{$t('td')}}</div>
      </div>
      <div @click="goPath('my')">
        <img class="icon-tab" :src="require('../../assets/tabbar/wd' + (value == 'my' ? '1.png' : '0.png'))">
        <div :class="value == 'my' ? 'icon-txt2' : 'icon-txt'">{{$t('wd')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {}
  },
  methods: {
    goPath(val) {
      if(localStorage.getItem('token')){
        if (this.value === val) return;
        this.$emit('changeTab', val);
        this.$router.push({
          name: val
        })
      }else{
       this.$toast(this.$t('qljqbhdl'))
      }
    }
  }
}
</script>
<style scoped>
.box {
  height: 70px;
}

.icon-huojian {
  width: 50px;
  height: 50px;
  margin-top: -13%;
}


.icon-txt2 {
  font-size: 12px;
  font-weight: 500;
  color: #0CC5BD;
  text-align: center;
  width: 50px;
}

.icon-txt {
  font-size: 12px;
  font-weight: 500;
  color: #B2B3B5;
  text-align: center;
  width: 50px;
}

.icon-tab {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: flex;
}

.tabbar {
  background-image: url("../../assets/tabbar/bj.png");
  background-size: 100% 100%;
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
</style>
