<template>
  <div>
    <router-view/>
    <Tabbar v-if="status" :value="value" @changeTab="changeTab"/>
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar/index.vue";
import {createWeb3Modal, defaultWagmiConfig} from "@web3modal/wagmi/vue";
import {bsc} from "viem/chains";
import {disconnect, watchAccount} from "@wagmi/core";
export default {
  components: {
    Tabbar,
  },
  data() {
    return {
      initWalletSuccess: false,
      status: true,
      value: "index",
    };
  },
  watch: {
    $route: function (e) {
      const whiteList = ["index", "introduce", "firing", "team", "my"];
      this.value = e.name;
      if (whiteList.indexOf(e.name) == -1) {
        this.status = false;
      } else {
        this.status = true;
      }
    },
  },
  async created() {
    const VUE_APP_VERSION = require('../package.json').version;
    let version = localStorage.getItem('version');
    if (!version || version !== VUE_APP_VERSION) {
      localStorage.setItem('version', VUE_APP_VERSION);
      window.location.reload()
    }
  },
  mounted() {
    // window.addEventListener('beforeunload', function(event) {
    //   // 在窗口关闭之前执行的操作
    //   localStorage.clear();
    //   this.$store.state.address = '';
    //   // 可以在这里添加清除Cookie、保存数据等操作
    //   event.preventDefault(); // 阻止默认的关闭行为
    //   event.returnValue = ''; // Chrome需要设置returnValue属性
    // });

    // this.check();
    //
    // setInterval(() => {
    //   this.check()
    // }, 4000)
    //
    // document.oncontextmenu = new Function("event.returnValue=false");
    //
    // document.onkeydown = () => {
    //   //禁用F12
    //   if (window.event && window.event.keyCode == 123) {
    //     return false;
    //     //禁用ctrl+shift+i,
    //   } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
    //     return false;
    //     //屏蔽Shift+F10
    //   } else if (window.event.shiftKey && window.event.keyCode == 121) {
    //     return false;
    //   }
    // };

    try {
      const projectId = "c735502cf0d064152e29c666871a9ff5";
      const metadata = {
        name: "Web3Modal",
        description: "Web3Modal Example",
        url: "https://web3modal.com",
        icons: ["https://avatars.githubusercontent.com/u/37784886"],
      };

      const chains = [bsc];
      const wagmiConfig = defaultWagmiConfig({chains, projectId, metadata});

      createWeb3Modal({wagmiConfig, projectId, chains});

      watchAccount(async res => {
        if (res.address && this.$store.state.address) {
          if (localStorage.getItem('username') !== res.address.toLowerCase()) {
            this.$toast(this.$t('qbdzfsbf'));
            await disconnect();
            localStorage.clear();
            this.$store.state.address = '';
            if (this.$route.name != 'index') {
              await this.$router.push({
                name: 'index'
              })
            }
          }
        }
      })
    } catch (e) {
    }
  },
  methods: {
    getOs() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      console.log(flag);
    },
    check() {
      function doCheck(a) {
        if (('' + a / a)['length'] !== 1 || a % 20 === 0) {
          ;(function () {
          }['constructor']('debugger')())
        } else {
          ;(function () {
          }['constructor']('debugger')())
        }
        doCheck(++a)
      }

      try {
        doCheck(0)
      } catch (err) {
      }
    },
    changeTab(e) {
      this.value = e;
    },
  },
};
</script>
<style>
html,
body {
  background: #f4f6f5 !important;
}

.padding-15-box {
  padding: 15px;
  box-sizing: border-box;
}

.ddd {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.xiaofangk {
  width: 4px;
  height: 15px;
  background: #44c3c6;
  border-radius: 2px;
  margin-right: 5px;
}

.jianjie {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
</style>
