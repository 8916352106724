import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
// import Vconsole from 'vconsole';
// new Vconsole();
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import api from './request/api.js'
Vue.prototype.$api = api;
import { Toast } from 'vant';
Vue.use(Toast)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.prototype.$toast = function (msg){
  Toast({
    message: msg,
    position: 'bottom',
  });
}
import Vue2OrgTree from 'vue-tree-color'
Vue.use(Vue2OrgTree)
import VueI18n from 'vue-i18n';
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'kr',
  messages: {
    en: require('../src/lang/en.json'),
    zh: require('../src/lang/zh.json'),
    kr: require('../src/lang/kr.json'),
    tv: require('../src/lang/tv.json'),
    ja: require('../src/lang/ja.json'),
    es: require('../src/lang/es.json'),
    ru: require('../src/lang/ru.json'),
  },
});

Vue.prototype.$copy = function (value){
  const aux = document.createElement('input')
  aux.setAttribute('value', value)
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
  Toast({
    message: this.$t('fzgc'),
    position: 'bottom',
  });
}

Vue.prototype.imgLogo = require('../src/assets/index/logo.png')
import store from './store'
Vue.prototype.$store = store;
Vue.prototype.$buyStatus = false;
Vue.prototype.img_url = 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2Fc03a255b-2dee-4ec6-afd3-9b5ca0af9d55%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1704017063&t=8497c69059cc6c9fdd0202c03210bf54'
new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
